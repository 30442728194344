<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="演出名称" name="performName" class="ui-form__item">
						<a-input v-model:value="formState.performName" placeholder="请输入演出名称"></a-input>
					</a-form-item>

					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>

					<a-form-item label="交易流水号" name="outTradeNo" class="ui-form__item">
						<a-input v-model:value="formState.outTradeNo" placeholder="请输入交易流水号"></a-input>
					</a-form-item>

					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
					</a-form-item>

					<a-form-item label="支付方式" name="payType" class="ui-form__item">
						<a-select v-model:value="formState.payType" placeholder="请选择支付方式" style="width: 190px;" allowClear>
							<a-select-option value="wx_lite">微信支付</a-select-option>
							<!-- <a-select-option value="alipay">支付宝支付</a-select-option> -->
							<a-select-option value="card">一卡通支付</a-select-option>
							<a-select-option value="zero">0元支付</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="优惠方式" name="discountType" class="ui-form__item">
						<a-select v-model:value="formState.discountType" placeholder="请选择优惠方式" style="width: 190px;" allowClear>
							<a-select-option :value="1">一卡通</a-select-option>
							<a-select-option :value="2">优惠券</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="演出时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>

					<a-form-item label="下单时间" class="ui-form__item">
						<a-range-picker v-model:value="createTime"></a-range-picker>
					</a-form-item>

					<a-form-item label="支付时间" class="ui-form__item">
						<a-range-picker v-model:value="payTime"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="16">
						<span v-permission="['performance_order_list_export']">
							<exportReport type="performOrderExportStrategy" :searchData="searchData"></exportReport>
						</span>
						<a-button v-permission="['performance_order_list_send']" style="margin-left: 10px;" type="primary" @click="onBatchSend">批量导入发货</a-button>
					</a-col>
					<a-col :span="8" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 10px;">
				<a-tabs v-model:activeKey="formState.status" type="card" @change="onSearch">
					<a-tab-pane :key="0" tab="全部"></a-tab-pane>
					<a-tab-pane :key="1" tab="待支付"></a-tab-pane>
					<a-tab-pane :key="2" tab="待出票"></a-tab-pane>
					<a-tab-pane :key="3" tab="待发货"></a-tab-pane>
					<a-tab-pane :key="4" tab="待收货"></a-tab-pane>
					<a-tab-pane :key="5" tab="已出票"></a-tab-pane>
					<a-tab-pane :key="6" tab="已完成"></a-tab-pane>
					<a-tab-pane :key="7" tab="已取消"></a-tab-pane>
					<a-tab-pane :key="8" tab="已售后"></a-tab-pane>
				</a-tabs>
				<cTable ref="cTable" style="padding: 0;" rowKey="orderId" :searchData="searchData" :columns="columns" :isPage="true" :isRequest="true" :requestFun="getPerformOrderList" :scroll="{ x: 4400 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'showStartTime'">
							{{ transDateTime(record.showStartTime) }}
						</template>
						<template v-if="column.key === 'user'">
							<div>
								<!-- <div>用户id：{{ record.user.memberId || '-' }}</div> -->
								<div>用户名称：{{ record.user.nickname || '-' }}</div>
								<div>用户手机号：{{ record.user.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.orderCreateTime) }}
						</template>
						<template v-if="column.key === 'payTime'">
							{{ transDateTime(record.payTime) }}
						</template>
						<template v-if="column.key === 'payType'">
							<div>
								<a-tag v-if="record.payType === 'wx_lite'" color="#87d068">微信支付</a-tag>
								<a-tag v-if="record.payType === 'alipay'" color="#2db7f5">支付宝支付</a-tag>
								<a-tag v-if="record.payType === 'card'" color="#f50">一卡通支付</a-tag>
								<a-tag v-if="record.payType === 'zero'">0元支付</a-tag>
							</div>
						</template>
						<template v-if="column.key === 'status'">
							{{ transStatus(record.status) }}
						</template>
						<template v-if="column.key === 'viewsInfo'">
							<div v-if="record.userViewerList.length === 0">-</div>
							<div v-else><a-button type="link" size="small" @click="onViewer(record)">查看观演人信息</a-button></div>
						</template>
						<template v-if="column.key === 'addressInfo'">
							<div v-if="record.deliveryType === 1 && record.receiverAddressInfoVo">
								<div>收货人名称：{{ record.receiverAddressInfoVo.name }}</div>
								<div>收货人手机号：{{ record.receiverAddressInfoVo.phone }}</div>
								<div>收货地址：{{ record.receiverAddressInfoVo.showAddress }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'sendInfo'">
							<div v-if="record.deliveryType === 1 && record.logisticsCompany">
								<div>物流公司：{{ record.logisticsCompany || '-' }}</div>
								<div>物流单号：{{ record.logisticsNo || '-' }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'isShow'">
							{{ record.isShow ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'divideTime'">
							<div v-if="record.divideTime && record.divideTime.length">
								<div v-for="item in record.divideTime">
									{{ transDateTime(item) }}
								</div>
							</div>
							<div v-else>-</div>
						</template>

						<template v-if="column.key === 'fees'">
							<div v-if="record.payType === 'wx_lite'">{{ (record.payment * 0.0054).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'predict'">
							<div v-if="record.payType === 'wx_lite'">{{ (record.payment - (record.payment * 0.0054)).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>

						<template v-if="column.key === 'afterInfo'">
							<div v-if="record.afterSale">
								<div>售后状态：{{ transRefundStatus(record.afterSale.refundStatus) }}</div>
								<div>服务费：￥{{ record.afterSale.totalServiceFee }}</div>
								<div>退款金额：￥{{ record.afterSale.refund }}</div>
								<div>发起端：{{ ['小程序', '后台', '柜台'][record.afterSale.refundUserType - 1] }}</div>
								<div>发起时间：{{ transDateTime(record.afterSale.createTime) }}</div>
								<div v-if="record.afterSale.processName">审批人：{{ record.afterSale.processName }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']" v-if="['WAIT_DIVIDE_SEAT', 'WAIT_SELLER_SEND_GOODS', 'WAIT_CHECK', 'TRADE_FINISH', 'WAIT_BUYER_CONFIRM_GOODS'].indexOf(record.status) !== -1">
								<a-button>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<div v-if="record.status === 'WAIT_SELLER_SEND_GOODS'" v-permission="['performance_order_list_send']" @click="onSendOrder(record)">
											<a-menu-item>
												发货
											</a-menu-item>
										</div>
										<div
											v-if="!record.sale"
											v-permission="['performance_order_list_refund']" @click="onAfter(record)"
										>
											<a-menu-item>
												售后
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
							<div v-else>-</div>
						</template>
					</template>
				</cTable>
			</div>

			<a-modal v-model:visible="showSendGoods" destroyOnClose title="发货信息" width="500px" @ok="onSendOrderSubmit">
				<a-spin :spinning="loading">
					<a-form ref="sendForm" scrollToFirstError :model="modelRef" name="sendForm" :labelCol="{span: 5}"
						:wrapperCol="{span: 15 }">
						<a-form-item label="快递公司" name="logisticsCompany" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<logisticsCompany v-model:value="modelRef.logisticsCompany" placeholder="请选择物流公司"></logisticsCompany>
						</a-form-item>

						<a-form-item label="快递单号" name="logisticsNo" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="modelRef.logisticsNo" :precision="0" placeholder="请输入快递单号"></a-input>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>

			<a-modal v-model:visible="showAfterModal" destroyOnClose title="售后" width="500px" @cancel="onRefundCancel" @ok="onRefundOk">
				<a-spin :spinning="loading">
					<a-form ref="afterForm" scrollToFirstError :model="afterModelRef" name="afterForm"
						:labelCol="{span: 5}" :wrapperCol="{span: 15 }">

						<a-form-item label="实付金额">
							{{ afterModelRef.payment }} 元
						</a-form-item>

						<a-form-item label="退款金额" name="refund" :rules="[{required: true, message:'必填项不允许为空'}]">
							<a-input-number v-model:value="afterModelRef.refund" :max="afterModelRef.payment" :min="0" :precision="2" placeholder="请输入"></a-input-number> 元
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>

			<a-modal v-model:visible="showImportTemplate" :title="title" width="400px" @ok="onImportTemplateOk">
				<a-spin :spinning="loading">
					<a-form ref="importFrom" name="importFrom" scrollToFirstError :model="importModelRef"
						:labelCol="{span: 6}" :wrapperCol="{span: 18}">
						<a-form-item label="Excel文件" name="fileList" :rules="[{required: true, message: '请上传文件'}]">
							<a-upload v-model:file-list="importModelRef.fileList" list-type="picture"
								action="/admin/ajaxUpload.do" accept=".xlsx" :max-count="1" :beforeUpload="beforeUpload">
								<a-button>
									<Icon icon="UploadOutlined"></Icon>
									上传
								</a-button>
							</a-upload>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>

			<a-modal v-model:visible="showSendManage" title="批量发货" width="500px">
				<a-spin :spinning="loading">
					<a-form scrollToFirstError :labelCol="{span: 8}" :wrapperCol="{span: 20}" style="margin-left: 30px;">
						<a-form-item extra="发货导入和物流单导入均需要使用该发货模版">
							<a-button type="link"
								@click="onExportTemplate">
								点击下载发货模板
							</a-button>
						</a-form-item>
						<a-form-item extra="使用发货模版导入物流信息，用于批量发货">
							<a-button type="link" style="background: rgb(36,159,126); color: white;"
								@click="onImportTemplate('发货导入')">
								发货导入
							</a-button>
						</a-form-item>
						<a-form-item extra="使用发货模版导入物流信息，用于修改物流信息">
							<a-button type="link" style="background: rgb(36,159,126); color: white;"
								@click="onImportTemplate('物流单导入')">
								物流单导入
							</a-button>
						</a-form-item>
					</a-form>
				</a-spin>
				<template #footer>
					<a-button type="primary" @click="showSendManage = false">关闭</a-button>
				</template>
			</a-modal>
		</a-spin>
		<a-modal v-model:visible="viewerModalVisible" title="观演人信息" width="750px">
			<template #footer>
				<a-button type="primary" @click="viewerModalVisible = false">确定</a-button>
			</template>
			<a-table :pagination="false" :dataSource="viewerList" :columns="viewerColumns">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'idType'">
						{{ ['身份证', '港澳台居民居住证', '港澳居民来往内地通行证', '台湾居民来往内地通行证', '护照', '外国人永久居留证', '军人证'][record.idType - 1] }}
					</template>
				</template>
			</a-table>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import logisticsCompany from '@/components/logisticsCompany/index.vue';
	import exportReport from '@/components/exportReport/exportReport.vue';
	import { getPerformOrderList, orderRefundSale } from '@/service/modules/perform.js';
	import { sendOrderBase, importOrderBase, importUpdateOrderBase, saveOrderAfterSale } from '@/service/modules/mall.js';
	export default {
		components: {
			Icon,
			cTable,
			exportReport,
			logisticsCompany
		},
		data() {
			return {
				showSendManage: false,
				showSendGoods: false,
				showAfterModal: false,
				loading: false,
				selectRowKeys: [],
				searchData: {},
				modelRef: {},
				afterModelRef: {},
				formState: {
					sale: 0
				},
				time: [],
				createTime: [],
				payTime: [],
				columns: [{
					title: '演出',
					dataIndex: 'performName',
				}, {
					title: '场次时间',
					key: 'showStartTime'
				}, {
					title: '票档',
					dataIndex: 'ticketName',
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
					width: 170
				}, {
					title: '下单时间',
					key: 'createTime',
					width: 160
				}, {
					title: '支付时间',
					key: 'payTime',
					width: 160
				}, {
					title: '交易流水号',
					dataIndex: 'outTradeNo',
					width: 230
				}, {
					title: '用户信息',
					key: 'user',
					width: 220
				}, {
					title: '票数量',
					dataIndex: 'ticketNum',
					width: 100
				}, {
					title: '是否已发放',
					key: 'isShow'
				}, {
					title: '已分配座位数',
					dataIndex: 'divideNum',
					width: 150
				}, {
					title: '待分配座位数',
					dataIndex: 'waitDivideNum',
					width: 150
				}, {
					title: '分配时间',
					key: 'divideTime'
				}, {
					title: '分配人',
					dataIndex: 'divideUserName',
					width: 180
				},  {
					title: '订单价格',
					dataIndex: 'orderPrice',
					width: 120
				}, {
					title: '实际支付',
					dataIndex: 'payment',
					width: 120
				}, {
					title: '优惠券名称',
					dataIndex: 'couponName'
				}, {
					title: '优惠券优惠金额',
					dataIndex: 'couponPrice',
					width: 130
				}, {
					title: '一卡通优惠',
					dataIndex: 'cardDiscountDeduction',
					width: 150
				}, {
					title: '支付方式',
					key: 'payType',
					width: 100
				}, {
					title: '订单状态',
					key: 'status',
					width: 100
				}, {
					title: '观演人',
					key: 'viewsInfo',
					width: 250
				}, {
					title: '收货信息',
					key: 'addressInfo',
					width: 250
				}, {
					title: '发货信息',
					key: 'sendInfo',
					width: 200
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
				showImportTemplate: false,
				title: '',
				importModelRef: {},
				viewerModalVisible: false,
				viewerList: [],
				viewerColumns: [{
					title: '观演人姓名',
					dataIndex: 'fullName'
				}, {
					title: '证件类型',
					key: 'idType'
				}, {
					title: '证件号',
					dataIndex: 'cardNumber'
				}],
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getPerformOrderList: getPerformOrderList,
			reset() {
				this.time = [];
				this.createTime = [];
				this.payTime = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.showStartTime = this.time[0].startOf('days').unix();
					this.searchData.showEndTime = this.time[1].endOf('days').unix();
				}
				if (this.createTime && this.createTime.length) {
					this.searchData.startCreateTime = this.createTime[0].startOf('days').unix();
					this.searchData.endCreateTime = this.createTime[1].endOf('days').unix();
				}
				if (this.payTime && this.payTime.length) {
					this.searchData.startPayTime = this.payTime[0].startOf('days').unix();
					this.searchData.endPayTime = this.payTime[1].endOf('days').unix();
				}
				this.getData(true);
			},
			getData(isReset) {
				if (isReset) {
					// 重置分页查询
					this.$refs.cTable.toQuery();
				} else {
					// 不重置分页查询
					this.$refs.cTable.refQuery();
				}
			},
			onAfter(item) {
				this.afterModelRef = {
					orderId: item.orderId,
					payment: item.payment,
				}
				this.showAfterModal = true;
			},
			onSendOrder(item) {
				this.modelRef = {
					id: item.orderId,
					logisticsCompany: undefined,
					logisticsNo: ''
				}
				this.showSendGoods = true;
			},
			onSendOrderSubmit() {
				this.$refs.sendForm.validate().then(() => {
					this.$confirm({
						title: '提示',
						content: '确定发货吗？',
						onOk: async ()=> {
							this.loading = true;
							let ret = await sendOrderBase(this.modelRef)
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('发货成功');
								this.getData();
							}
						}
					})
				})
			},
			onViewer(item) {
				this.viewerList = item.userViewerList || [];
				this.viewerModalVisible = true;
			},
			onBatchSend(){
				this.showSendManage = true;
			},
			onExportTemplate() {
				let a = document.createElement('a');
				if (window.location.href.indexOf('dashboard') > -1) {
					a.href = `/dashboard/导入发货模板.xlsx`;
				} else {
					a.href = `/导入发货模板.xlsx`;
				}
				a.download = '发货模板.xlsx';
				a.click();
			},
			onImportTemplate(title) {
				this.title = title;
				this.showImportTemplate = true;
			},
			onImportTemplateOk() {
				if (this.importModelRef.fileList.length === 0) {
					return this.$message.warn('请先上传文件');
				}
				const isLt1M = this.importModelRef.fileList[0].size / 1024 / 1024 < 1;
				if (!isLt1M) {
					return this.$message.error('上传文件太大了, 最大只允许1M');
				}
				this.$refs.importFrom.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret;
						if (this.title === '发货导入') {
							ret = await importOrderBase({}, {
								file: this.importModelRef.fileList[0]
							});
						} else if (this.title === '物流单导入') {
							ret = await importUpdateOrderBase({}, {
								file: this.importModelRef.fileList[0]
							});
						}
						this.loading = false;
						if (ret.code === 200) {
							this.showImportTemplate = false;
							this.importModelRef.fileList = [];
							this.$message.success('操作成功');
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 5;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许5M');
				}
				return false;
			},
			onRefundCancel() {
				this.$refs.afterForm.resetFields();
				this.showAfterModal = false;
			},
			onRefundOk() {
				this.$refs.afterForm.validate().then(() => {
					this.$confirm({
						title: '提示',
						content: '确定售后吗？',
						onOk: async ()=> {
							this.loading = true;
							let ret = await orderRefundSale(this.afterModelRef);
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功')
								this.getData();
								this.onRefundCancel();
							}
						}
					})
				})
			},
			onRowChange(data) {
				this.selectRowKeys = data.keys;
			},
			transRefundStatus(status) {
				let value = '';
				switch(status) {
					case 1:
						value = '审核中';
						break;
					case 2:
						value = '待用户发货';
						break;
					case 3:
						value = '用户已发货';
						break;
					case 4:
						value = '审核不通过';
						break;
					case 5:
						value = '用户已撤回';
						break;
					case 6:
						value = '售后完成';
						break;
					case 7:
						value = '改签成功';
						break;
					default:
						value = '-';
						break;
				}
				return value;
			},
			transStatus(status) {
				let value = '';
				switch(status) {
					case 'SYSTEM_CANCEL':
						value = '已关闭'
						break;
					case 'CUSTOMER_CANCEL':
						value = '已关闭'
						break;
					case 'WAIT_BUYER_PAY':
						value = '待付款'
						break;
					case 'WAIT_DIVIDE_SEAT':
						value = '待出票';
						break;
					case 'WAIT_SELLER_SEND_GOODS':
						value = '待发货'
						break;
					case 'WAIT_CHECK':
						value = '已出票-待核销'
						break;
					case 'WAIT_BUYER_CONFIRM_GOODS':
						value = '待确认收货'
						break;
					case 'WAIT_COMMENT':
						value = '待评价'
						break;
					case 'TRADE_FINISH':
						value = '已完成'
						break;
					case 'AFTERSALE_FINISH':
						value = '已售后'
						break;
					default:
						value = '-';
						break;
				}
				return value;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-viewer {
		padding: 10px 10px;
		border: solid 1px #eee;
		border-radius: 10px;
	}
	.ui-viewer + .ui-viewer {
		margin-top: 10px;
	}
</style>
